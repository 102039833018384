
import { defineComponent, ref, onMounted, computed, onUpdated } from "vue";
import ExportCustomerModal from "@/components/modals/forms/ExportCustomerModal.vue";
import AddStockRegisterModal from "@/components/modals/forms/AddStockRegisterModal.vue";
import AddProductListSalesEnquiry from "@/components/modals/forms/AddProductListSalesEnquiry.vue";
// import Datatable from "@/components/kt-datatable/KTDatatable.vue";

import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
// import MixedWidget7 from "@/components/widgets/mixed/Widget7.vue";
import MixedWidget10 from "@/components/widgets/mixed/Widget10.vue";
import customers from "@/core/data/customers";
import roc_list, { business_types } from "@/core/data/genericData";
import { ICustomer } from "@/core/data/customers";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { array } from "yup/lib/locale";
import JwtService from "@/core/services/JwtService";
import { Modal } from "bootstrap";
import { ActionsFi } from "@/store/enums/StoreFiEnums";
import moment from "moment";

export default defineComponent({
  name: "customers-listing",
  components: {
    ExportCustomerModal,
    AddStockRegisterModal,
    AddProductListSalesEnquiry,
    // MixedWidget7,
    MixedWidget10,
    // Datatable,
  },

  setup() {
    const count = ref(0);
    const store = useStore();
    const checkedCompany = ref([]);
    const loadingData = ref<boolean>(false);
     const loading = ref<boolean>(false);
    const listVisible = ref<boolean>(true);
    const searchTearm = ref("");
    var formDataViewValue = ref({ valuetbl2: ""});

    interface WIJournal {
      stock_id: string;
      manufacturer: string;
      category_description: string;
      standard_description: string;
      opening_quantity: string;
      inward_quantity: string;
      outward_quantity: string;
      closing_quantity: string;
      warehouese_name: string;
      tn_ref: string;
      remark: string;
      company_batch_no: string;
      material_condition: string;
      hsn_code: string;
      category_name: string;
      plant_name: string;
      brand_name: string;
      rate: string;
      amount: string;
    }

    interface WICompanies {
      company_id: number;
      user: {
        avatar: string;
        name: string;
        group: string;
      };
      comp_type: string;
      status: {
        label: string;
        color: string;
      };
      active: {
        label: string;
        color: string;
      };
      roc: string;
      nob: string;
      doi: string;
      pan: string;
    }

    const view_modal = (stock_id, stock_details) => {
      const modal = new Modal(
        document.getElementById("modal_id")
      );
      modal.show();
      (document.querySelector("#value_tbl2") as HTMLInputElement).value =
        stock_id;
     modal.show();
     
     const table_Data = stock_details.map(value => {
        return (
          `<tr>
            
            <td>${value.company_batch_no}</td>
            <td>${value.stock_quantity}</td>
            <td>${value.gross_quantity}</td>
            <td>${value.no_of_pieces}</td>
            <td>${value.available_quantity}</td>
            <td>${value.material_condition}</td>
          </tr>`
        );
      }).join('');
      //   return (
      //     `<div>
            
      //       <div><b>Company Batch No :</b> ${value.company_batch_no}</div>
      //       <div><b>Stock Quantity : </b>${value.stock_quantity}</div>
      //       <div><b> Gross Quantity : </b>${value.gross_quantity}</div>
      //       <div><b> No 0f Pieces : </b>${value.no_of_pieces}</div>
      //       <div><b> Available Quantity : </b> ${value.available_quantity}</div>
      //       <div><b> Material Condition : </b>${value.material_condition}</div>
      //     </div>`
      //   );
      // }).join('');
      (document.querySelector("#value_tbl2") as HTMLInputElement).innerHTML= table_Data;
      
    };

    var paginationData = ref({});

    paginationData.value = {
      start: 0,
      end: 0,
      total: 0,
      perPage: 5,
      activePage: 1,
      totPage: 1,
      pageRange: [1],
    };

    const formData = ref({
      startDate: "",
      endDate: "",
    });

    const refreshData = () => {
      paginationData.value["activePage"] = 1;
      getCompanyList(searchTearm.value);
    };

    const changePageChange = (page) => {
      paginationData.value["activePage"] = page;
      getCompanyList(searchTearm.value);
    };

    var tableData = ref<Array<WIJournal>>([]);

    const getCompanyList = async (data) => {
      //loadingData.value = true;
      var user = JSON.parse(JwtService.getToken());
       var start_data = ""
        var end_date = ""
      if(!formData.value['startDate']){
          start_data = ""
          end_date = ""
      }
      else
      {
        start_data = moment(formData.value.startDate[0]).format("YYYY-MM-DD")
        end_date = moment(formData.value.startDate[1]).format("YYYY-MM-DD")
      }
      try {
        var values = {
          from_date: start_data,
          to_date: end_date,
          page: parseInt(paginationData.value["activePage"]),
          records_per_page: parseInt(paginationData.value["perPage"]),
          user_id: user.user_id,
        };
        console.log(values);
        await store
          .dispatch(ActionsFi.CUST_GET_STOCK_REGISTER_LISTING, values)
          .then((data) => {
            console.log("total data:" + JSON.stringify(data));

            // data.data = data.data.map((item) => {
            //   let typeValue = type_data.value.filter((type) => {
            //     return (
            //       type.stock_product_type_id === item.stock_product_type_id
            //     );
            //   });
            //   item.stock_product_type = typeValue[0]?.stock_product_type;
            //   return item;
            // });
           tableData.value = [];
            const totalCount = data?.data?.total_records;
            // set pagination
            paginationData.value["total"] = data?.data?.total_records;
            paginationData.value["start"] = data?.data?.records_from;
            paginationData.value["end"] = data?.data?.records_upto;
            paginationData.value["activePage"] = data?.data?.page;
            paginationData.value["totPage"] = data?.data?.total_pages;
            paginationData.value["pageRange"] = [];
            for (
              let index = data?.data?.bar_range_start;
              index < data?.data?.bar_range_end;
              index++
            ) {
              paginationData.value["pageRange"].push(index);
            }
            //endpagination
            data = data?.data?.result_list;

            var resultsM = ref<Array<WIJournal>>([]);
            var status_label = "";
            var status_color = "";
            var active_label = "";
            var active_color = "";
            
            for (let j = 0; j < data.length; j++) {
              resultsM.value = Array({
                stock_id: data[j]["stock_id"],
                category_name: data[j]["category_name"],
                category_description: data[j]["category_description"],
                manufacturer: data[j]["manufacturer"],
                warehouese_name: data[j]["warehouese_name"],
                tn_ref: data[j]["tn_ref"],
                remark: data[j]["remark"],
                amount: data[j]["product_amount"],
                rate: data[j]['product_rate'],
                opening_quantity: data[j]["opening_quantity"],
                inward_quantity: data[j]["inward_quantity"],
                outward_quantity: data[j]["outward_quantity"],
                closing_quantity: data[j]["closing_quantity"],
                standard_description : data[j]["standard_description"],
                company_batch_no : data[j]["company_batch_no"],
                material_condition : data[j]["material_condition"],
                hsn_code : data[j]["hsn_code"],
                plant_name: data[j]["plant_name"],
                brand_name: data[j]["brand_name"],
              });
              console.log("resultsm:" + JSON.stringify(resultsM.value));
              tableData.value.splice(
                j,
                resultsM.value.length,
                ...resultsM.value
              );
            }

            loadingData.value = false;
          })
          .catch(({ response }) => {
            tableData.value = [];
            loadingData.value = false;
          });
      } catch (e) {
        console.log(e);
      }
    };

    // onUpdated(() => {          });

    // var tableData = ref<Array<WICompanies>>([]);
    const initCustomers = ref<Array<WIJournal>>([]);

    onMounted(async () => {
      await getCompanyList(searchTearm.value);
      setCurrentPageBreadcrumbs("Stock Register", []);
      initCustomers.value.splice(0, tableData.value.length, ...tableData.value);
    });

    const product_add_modal = (sales_enquiry_id) => {
      const modal = new Modal(
        document.getElementById("kt_modal_add_product_sales_enquiry")
      );
      modal.show();
      (document.querySelector("#sales_enquiry_id") as HTMLInputElement).value =
        sales_enquiry_id;
    };

    const search = ref<string>("");
    const searchItems = () => {
      searchTearm.value = search.value;
      getCompanyList(search.value);
      console.log(search.value);
      // tableData.value.splice(0, tableData.value.length, ...initCustomers.value);
      // if (search.value !== "") {
      //   let results: Array<WICompanies> = [];
      //   for (let j = 0; j < tableData.value.length; j++) {
      //     if (searchingFunc(tableData.value[j], search.value)) {
      //       results.push(tableData.value[j]);
      //     }
      //   }
      //   tableData.value.splice(0, tableData.value.length, ...results);
      // }
    };
    function showList() {
      listVisible.value = true;
      console.log("list visible.." + showList);
    }
    function hideList() {
      listVisible.value = false;
    }
    const searchingFunc = (obj, value): boolean => {
      for (let key in obj) {
        if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {
          if (obj[key].indexOf(value) != -1) {
            return true;
          }
        }
      }
      return false;
    };
    const downtype = ref([]);
    const download = async () => {
      var user = JSON.parse(JwtService.getToken());
       var start_data = ""
        var end_date = ""
      if(!formData.value['startDate']){
          start_data = ""
          end_date = ""
      }
      else
      {
        start_data = moment(formData.value.startDate[0]).format("YYYY-MM-DD")
        end_date = moment(formData.value.startDate[1]).format("YYYY-MM-DD")
      }
      const values = {
        from_date: start_data,
        to_date: end_date,
        user_id : user.user_id,
      };
      loading.value = true;
      try {
        await store
          .dispatch(ActionsFi.DOWNLOAD_STOCK_DATA,values)
          .then(({ data }) => {
            downtype.value = data
            window.location =data.file_url
            loading.value = false;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }

    }

    return {
      tableData,
      search,
      searchItems,
      checkedCompany,
      count,
      getCompanyList,
      paginationData,
      changePageChange,
      refreshData,
      loadingData,
      loading,
      product_add_modal,
      showList,
      listVisible,
      hideList,
      formData,
      view_modal,
      formDataViewValue,
      download,
     downtype
    };
  },
});
